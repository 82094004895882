import type {ReactNode} from 'react';

import {useTranslations} from '@/hooks/useTranslations';
import type {Article, BLOG_PAGE_TYPE} from '@/components/blog/types';
import Hero from '@/components/pages/blog/Hero/Hero';
import LatestSection from '@/components/pages/blog/LatestSection/LatestSection';
import Conversion, {
  HeadingStyles,
} from '@/components/pages/blog/SubscribeForm/Conversion/Conversion';
import ArticleCarousel from '@/components/pages/blog/ArticleCarousel/ArticleCarousel';

interface SimpleSectionsIndexProps {
  featuredHeroArticle?: Article;
  heroArticles?: Article[];
  latestArticles?: Article[];

  category1Articles?: Article[];
  category2Articles?: Article[];
  category3Articles?: Article[];
  category4Articles?: Article[];

  settings: {
    [key: string]: {
      heading: string;
      url: string;
      preferredBlogCardTag?: string;
    };
  };
  mostReadArticles?: Article[];
  enBlogPath: string;
  heroConversionCta?: ReactNode;
  blogType: BLOG_PAGE_TYPE;
}

export default function SimpleSectionsIndex({
  featuredHeroArticle,
  heroArticles = [],
  latestArticles = [],
  category1Articles = [],
  category2Articles = [],
  category3Articles = [],
  category4Articles = [],
  mostReadArticles = [],
  settings = {},
  enBlogPath,
  heroConversionCta,
  blogType,
}: SimpleSectionsIndexProps) {
  const {t} = useTranslations();

  return (
    <>
      <Hero
        featuredArticle={featuredHeroArticle}
        secondaryArticles={heroArticles}
        enBlogPath={enBlogPath}
        conversionCta={heroConversionCta}
      />
      {latestArticles.length > 0 && (
        <LatestSection
          sectionHead={t('latestArticles')}
          primaryArticles={latestArticles}
          enBlogPath={enBlogPath}
        />
      )}

      {category1Articles.length > 0 && (
        <ArticleCarousel
          heading={settings.category1.heading}
          primaryArticles={category1Articles}
          enBlogPath={enBlogPath}
          link={{
            text: t('carouselSeeAllLinkText'),
            url: settings.category1.url,
          }}
          carouselName={settings.category1.url.split('/').pop()!}
          headingTag="h5"
          preferredBlogCardTag={
            settings.category1.preferredBlogCardTag ||
            settings.category1.heading
          }
        />
      )}
      <Conversion
        {...t('conversion')}
        headingClassName={HeadingStyles}
        className="border-y container py-16"
        cta={{...t('conversionSubscription.conversionCta')}}
        mode="light"
        subscriptionId="remove-me"
        blogType={blogType}
      />
      {category2Articles.length > 0 && (
        <ArticleCarousel
          heading={settings.category2.heading}
          primaryArticles={category2Articles.slice(0, 4)}
          enBlogPath={enBlogPath}
          link={{
            text: t('carouselSeeAllLinkText'),
            url: settings.category2.url,
          }}
          carouselName={settings.category2.url.split('/').pop()!}
          headingTag="h5"
          preferredBlogCardTag={
            settings.category2.preferredBlogCardTag ||
            settings.category2.heading
          }
        />
      )}
      {category3Articles.length > 0 && (
        <ArticleCarousel
          className="bg-[#F6F6F1]"
          heading={settings.category3.heading}
          primaryArticles={category3Articles.slice(0, 4)}
          enBlogPath={enBlogPath}
          link={{
            text: t('carouselSeeAllLinkText'),
            url: settings.category3.url,
          }}
          carouselName={settings.category3.url.split('/').pop()!}
          headingTag="h5"
          preferredBlogCardTag={
            settings.category3.preferredBlogCardTag ||
            settings.category3.heading
          }
        />
      )}
      {category4Articles.length > 0 && (
        <ArticleCarousel
          heading={settings.category4.heading}
          primaryArticles={category4Articles.slice(0, 4)}
          enBlogPath={enBlogPath}
          link={{
            text: t('carouselSeeAllLinkText'),
            url: settings.category4.url,
          }}
          carouselName={settings.category4.url.split('/').pop()!}
          headingTag="h5"
          preferredBlogCardTag={
            settings.category4.preferredBlogCardTag ||
            settings.category4.heading
          }
        />
      )}

      {mostReadArticles.length > 0 && (
        <ArticleCarousel
          {...t('mostRead')}
          primaryArticles={mostReadArticles.slice(0, 4)}
          enBlogPath={enBlogPath}
          carouselName="most-read"
          className="bg-[#F6F6F1]"
          headingTag="h4"
        />
      )}
    </>
  );
}
